import React from 'react';

const Scroll = (props) => {
	return (
		<div className='mt-4' style={{overflow: 'scroll', border: '2px solid black', height: '500px'}}>
		{props.children}
		</div>
		);
}

export default Scroll;